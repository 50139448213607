/* src/components/AboutUs/AboutUs.css */

.about-us {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    text-align: center;
    min-height: 70vh;
  }
  

  .about-us h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .about-us p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    width: 50%;
    margin: auto;
  }
  
  .about-us span {
    display: inline-block;
    margin-right: 0.3rem;
  }

  @media (max-width: 768px) {

    .about-us p {
      width: 100%;
      margin: auto;
      word-wrap: break-word;
      overflow-wrap: anywhere;
      margin: 0;
      padding: 0;
      font-size: 1rem;
    }


  }