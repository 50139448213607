/* src/components/Banner/Banner.css */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Adjust the height as needed */
  background-color: #f0f0f0; 
  padding: 20px;
}

.banner-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  text-align: center;
}

.banner-logo {
  height: 30vh; 
  margin-bottom: 15px; /* Add space between the logo and the title */
  animation: rotateY 5s linear infinite; /* Adjust the duration as needed */
  transform: perspective(100px) rotateY(0deg); /* Initial state */
  background: none;
}

.banner-title {
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #333; /* Text color */
  position: relative;
  font-family: 'Press Start 2P', cursive; /* Glitchy font */
  animation: glitch 1s infinite;
  margin-top: 5rem;
}

@keyframes rotateY {
  from {
    transform: perspective(100px) rotateY(0deg);
  }
  to {
    transform: perspective(100px) rotateY(360deg);
  }
}

@keyframes glitch {
  0% {
    text-shadow: 2px 2px #96f3c5, -2px -2px #03b1fc;
  }
  20% {
    text-shadow: -2px -2px #96f3c5, 2px 2px #03b1fc;
  }
  40% {
    text-shadow: 2px -2px #f85959, -2px 2px #03b1fc;
  }
  60% {
    text-shadow: -2px 2px #f85959, 2px -2px #03b1fc;
  }
  80% {
    text-shadow: 2px 2px #96f3c5, -2px -2px #03b1fc;
  }
  100% {
    text-shadow: -2px -2px #96f3c5, 2px 2px #03b1fc;
  }
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .banner {
    height: auto; /* Adjust height for mobile */
    padding: 10px;
    min-height: 70vh
  }

  .banner-logo {
    height: 10rem; /* Adjust logo size for mobile */
    margin-bottom: 10px;
  }

  .banner-title {
    font-size: 18px; /* Adjust font size for mobile */
  }
}