
.included-services {
    padding: 20px;
    background-color: #f9f9f9; /* Light background for clarity */
    border-radius: 8px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .services-list {
    list-style-type: none;
    padding: 0;
  }
  
  .service-item {
    margin-bottom: 20px;
  }
  
  .service-title {
    font-size: 1.2rem;
    color: #111;
    margin-bottom: 8px;
  }
  
  .service-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    /* For tablets and mobile devices */
    .section-title {
      font-size: 1.3rem;
    }
  
    .service-title {
      font-size: 1.1rem;
    }
  
    .service-description {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    /* For small mobile devices */
    .included-services {
      padding: 15px;
    }
  
    .section-title {
      font-size: 1.2rem;
    }
  
    .service-title {
      font-size: 1rem;
    }
  
    .service-description {
      font-size: 0.9rem;
    }
  }